import { useRoute } from 'vue-router';
import { useMutation, useQuery } from '@vue/apollo-composable';

import { useLeaveConfirmation } from '@/helpers/routing';
import { useResourcesStore } from '@/stores/resources';
import { useLocationsStore } from '@/stores/locations';

import type { DayOption, ServiceVariationsFormData } from './types';
import {
  CREATE_SERVICE_VARIATION_GROUP,
  GET_SERVICE_VARIATION_GROUP,
  UPDATE_SERVICE_VARIATION_GROUP
} from '../graphql';
import { DaysOfWeek } from '@/types';

const daysOfWeek = [
  DaysOfWeek.Mo,
  DaysOfWeek.Tu,
  DaysOfWeek.We,
  DaysOfWeek.Th,
  DaysOfWeek.Fr,
  DaysOfWeek.Sa,
  DaysOfWeek.Su
];

const getServiceOfferingsDefaults = (): DayOption[] =>
  daysOfWeek.map((dayOfWeek) => ({
    available: true,
    dayOfWeek,
    endTime: '',
    max: 0,
    startTime: ''
  }));

const formatSlotsData = (slots: DayOption[]) =>
  getServiceOfferingsDefaults().map((option) => {
    const slot = slots.find(
      (slot: DayOption) => slot.dayOfWeek === option.dayOfWeek
    );

    return {
      available: !!slot,
      dayOfWeek: slot?.dayOfWeek || option.dayOfWeek,
      endTime: slot?.endTime || option.endTime,
      max: slot?.max || option.max,
      startTime: slot?.startTime || option.startTime
    };
  });

export const useServiceVariations = (id?: number) => {
  const route = useRoute();
  const isLoading = ref(false);
  const { locationIds } = useLocationsStore();

  const { resourcesByType } = useResourcesStore();
  const resources = resourcesByType('employee');
  const resourceIds = resources.map((resource) => resource.id);

  const formData: ServiceVariationsFormData = reactive({
    bookable: true,
    bookableLocationIds: locationIds,
    categoryId: route.params.categoryId
      ? parseInt(route.params.categoryId as string)
      : 0,
    description: '',
    extraInformation: '',
    medical: false,
    name: '',
    serviceOfferingEnabled: false,
    serviceOfferingAttributes: {
      offeringType: 'WEEKDAYS',
      payload: {
        slots: getServiceOfferingsDefaults()
      }
    },
    requirementsAttributes: [
      {
        _uid: 1,
        destroy: false,
        primary: true,
        resourceIds,
        type: 'EMPLOYEE'
      }
    ],
    rwgName: '',
    vatRateId: null,
    variations: [
      {
        name: '',
        buffer: 0,
        duration: 0,
        durationFinish: 0,
        durationProcessing: 0,
        durationSetup: 0,
        requiresProcessingTime: false,
        price: 0,
        variationGroupSortOrder: 0
      }
    ]
  });

  const { resetConfirmation } = useLeaveConfirmation(formData);

  const onlineBookableError = computed(
    () => !formData.bookableLocationIds?.length && formData.bookable
  );
  nextTick(() => {
    resetConfirmation();
  });

  if (id) {
    isLoading.value = true;
    const { onResult } = useQuery(GET_SERVICE_VARIATION_GROUP, { id });
    onResult(({ data: { serviceVariationGroup } }) => {
      formData.id = serviceVariationGroup.id;
      isLoading.value = false;

      Object.keys(formData).forEach((key) => {
        if (serviceVariationGroup[key] !== undefined) {
          formData[key] = serviceVariationGroup[key];
        }
      });

      formData.requirementsAttributes = serviceVariationGroup.requirements.map(
        (req) => ({
          destroy: false,
          primary: req.primary,
          resourceIds: req.resources.map((r) => r.id),
          type: req.type,
          groupId: req.groupId
        })
      );

      formData.serviceOfferingEnabled = serviceVariationGroup.offeringEnabled;

      const slots =
        serviceVariationGroup?.serviceOffering?.payload?.slots || [];
      formData.serviceOfferingAttributes.payload.slots = formatSlotsData(slots);

      formData.variations = serviceVariationGroup.variations.map(
        (variation) => ({
          id: variation.id,
          name: variation.name,
          buffer: variation.buffer,
          duration: variation.duration,
          durationFinish: variation.durationFinish,
          durationProcessing: variation.durationProcessing,
          durationSetup: variation.durationSetup,
          requiresProcessingTime: variation.requiresProcessingTime,
          price: variation.price,
          variationGroupSortOrder: variation.variationGroupSortOrder
        })
      );

      formData.categoryId = serviceVariationGroup.category.id;

      nextTick(() => {
        resetConfirmation();
      });
    });
  }

  const getCleanedUpData = () => {
    const cleanData = { ...formData };
    cleanData.requirementsAttributes = cleanData.requirementsAttributes.map(
      (r) => ({
        ...r,
        _uid: undefined
      })
    );

    // if we have only one variation, its name should be the same as the group name
    if (cleanData.variations.filter((v) => !v.destroy).length === 1) {
      const firstVariationIndex = cleanData.variations.findIndex(
        (v) => !v.destroy
      );
      cleanData.variations[firstVariationIndex].name = cleanData.name;
    }

    /*   This is the service offering setup cleanup.
     *   If we have default setting there we want to disable it
     *   for backend performance reasons
     */
    const serviceOfferingSet =
      !!cleanData.serviceOfferingAttributes.payload.slots.find(
        (option) =>
          option.max || option.startTime || option.endTime || !option.available
      );
    if (!serviceOfferingSet) {
      cleanData.serviceOfferingEnabled = false;
    }

    if (cleanData.serviceOfferingAttributes) {
      cleanData.serviceOfferingAttributes.payload.slots =
        cleanData.serviceOfferingAttributes.payload.slots
          .filter((slot) => slot.available)
          .map((slot) => {
            const { dayOfWeek, endTime, startTime, max } = slot;
            return { dayOfWeek, endTime, startTime, max };
          });
    }

    return cleanData;
  };

  const createServiceVariationGroup = () => {
    const { mutate } = useMutation(CREATE_SERVICE_VARIATION_GROUP, {
      variables: {
        input: getCleanedUpData()
      }
    });

    return mutate();
  };

  const updateServiceVariationGroup = () => {
    const { mutate } = useMutation(UPDATE_SERVICE_VARIATION_GROUP, {
      variables: {
        input: getCleanedUpData()
      }
    });

    return mutate();
  };

  return {
    isLoading,
    formData,
    onlineBookableError,
    resetConfirmation,
    createServiceVariationGroup,
    updateServiceVariationGroup
  };
};
